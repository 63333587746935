import { Animator, Timeline } from "optimo-animator";
import { setCss, waitForFontsToBeLoaded } from "./utils";

enum Actions {
  Start = "start",
  Stop = "stop",
  Reset = "reset",
}

const MAX_RATIO = 2.2;

const isMobile = () => {
  return window.innerWidth / window.innerHeight < MAX_RATIO;
};

(async () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const delay = urlParams.get("delay") === "true";

  await waitForFontsToBeLoaded();
  const lines = Array.from(document.querySelectorAll(".line")) as HTMLElement[];
  let translateTargets: number[] = [];

  const updateTranslateTargets = () => {
    translateTargets = [];
    lines.forEach((line) => {
      const spans = line.querySelectorAll("span");
      const box = spans[0].getBoundingClientRect();
      translateTargets.push(box.width);
    });
  };

  updateTranslateTargets();

  const timeline = new Timeline([
    {
      duration: 25000,
      handler(progress) {
        lines.forEach((line, index) => {
          const p = index % 2 === 0 ? progress : 1 - progress;
          setCss(line, {
            transform: `translate3d(-${translateTargets[index] * p}px, 0, 0)`,
          });
        });
      },
    },
  ]);

  const animator = new Animator([timeline]);

  const updateIsMobile = () => {
    const lines = document.querySelector(".lines");

    if (isMobile()) {
      lines?.classList.add("is-mobile");
    } else {
      lines?.classList.remove("is-mobile");
    }
  };

  window.addEventListener("resize", () => {
    updateTranslateTargets();
    updateIsMobile();
  });

  updateIsMobile();

  if (delay) {
    animator.stop();
    animator.startTime = 0;
    animator.computeFrameAt(0);
    window.addEventListener(
      "message",
      ({ data }) => {
        const { action } = data;
        if (action === Actions.Start) {
          animator.start();
        } else if (action === Actions.Stop) {
          animator.stop();
        } else if (action === Actions.Reset) {
          animator.startTime = 0;
          animator.computeFrameAt(0);
        }
      },
      false
    );
  } else {
    animator.start();
  }

  // document.addEventListener("visibilitychange", () => {
  //   if (document.hidden) {
  //     animator.stop();
  //   } else {
  //     animator.startTime = Date.now();
  //     animator.start();
  //   }
  // });
})();
