export const setCss = (
  element: HTMLElement,
  style: Partial<CSSStyleDeclaration>
) => {
  for (const [key, value] of Object.entries(style)) {
    element.style[key] = value;
  }
};

export const waitForFontsToBeLoaded = (): Promise<void> => {
  return new Promise((resolve) => {
    document.fonts.ready.then(function () {
      resolve();
    });
  });
};
